import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Seo from '../components/SEO/PageSeo';
import Banner from '../components/Banner';
export const _frontmatter = {
  "title": "About Us"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo mdxType="Seo" />
  <Hero mdxType="Hero" />
    <section>
      <div className="page-center text-center">
          <h1>{`Allemagne - Prix élevés déraisonnables ou beauté déraisonnable ?`}</h1>
          <div className="underline"></div>
          <div className="posts-center">
            <article>
Nous vivons dans une région qui est - avouons-le - moche. C'est une ancienne région industrielle qui est en déclin depuis 20 ans.
Elle est loin de la mer, des montagnes ou de la nature.
Au lieu de cela, nous sommes entourés de villes en béton, d'autoroutes encombrées et de cieux gris. Comment en sommes-nous arrivés là ?
              <p>{`Les prix des maisons sont déraisonnablement élevés. Pour le prix d'un appartement, nous pouvions acheter une maison avec un jardin (et c'était le cas) là où nous vivions auparavant.
Nous avons donc commencé à chercher des maisons dans une région où nous avons déjà vécu, et que nous aimons vraiment : La Gironde, France.`}</p>
              <p>{`Avec Corona et home-office, nous nous sommes dit : pourquoi ne pas acheter une maison en France ?`}</p>
              <h2>{`Deux scénarios`}</h2>
              <p>{`Aujourd'hui, le pire scénario semble être le suivant : nous aurons une maison pour nos vacances dans une belle région et pour notre retraite.
Dans le meilleur des cas, nous aurons une maison pour y vivre. Dans tous les cas, cela nous semble assez génial.`}</p>
              <h3>{`Trouver une maison`}</h3>
              <p>{`En février 2021, nous avons commencé à chercher des maisons à proximité de notre ancien spot de surf, Carcans Plage. Sur les 10-15 maisons que nous avons visitées, aucune n'était à notre goût.
Non pas parce qu'elles n'étaient pas belles, mais parce qu'elles étaient soit construites sur un marécage, soit pas construites avec la qualité que nous attendions. Beaucoup de gens construisent des maisons bon marché de qualité médiocre pour les vendre à bas prix. Mais nous désirons une maison qui dure longtemps dans le temps, alors nous avons pensé...`}</p>
              <h3>{`Pourquoi ne pas construire une maison ?`}</h3>
              <p>{`Très bien, pourquoi ne pas construire une maison et la faire construire comme nous l'apprécierions ? Il y a de nombreuses raisons pour lesquelles construire une maison peut être génial. Avec un peu de chance, vous obtenez la maison de vos rêves, avec la répartition que vous voulez, les couleurs que vous aimez. Et elle est toute neuve et resplendissante.`}</p>
              <p>{`Mais construire une maison, c'est aussi beaucoup de stress et il y a beaucoup de choses dont vous devez être conscient et c'est ce dont ce blog parle.`}</p>
              <h2>{`De quoi parle ce blog ?`}</h2>
              <p>{`Nous allons présenter ici toutes les différentes étapes de la construction d'une maison, en commençant par le terrain, le crédit bancaire, toutes les formalités administratives et toutes les étapes de la construction. Nous publierons des infos et des photos tout au long du projet, jusqu'à ce que notre maison soit terminée. Nous avons choisi l'entreprise de construction MCA, suivez-nous et obtenez des informations privilégiées sur la façon dont tout s'est déroulé.`}</p>
            </article>
            <article>
  <Banner mdxType="Banner" />
            </article>
          </div>
        </div>
      </section>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      